.__radio__button__ {
  width: 20px;
  height: 20px;
  border: 1px solid #000000;
  box-sizing: border-box;
}

/* TODO remove later */
.__animate__page {
  background: red;
  animation-name: example;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-delay: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes example {
  0% {
    background-color: red;
    left: 0px;
    top: 0px;
  }

  25% {
    background-color: yellow;
    left: 200px;
    top: 0px;
  }

  50% {
    background-color: blue;
    left: 200px;
    top: 200px;
  }

  75% {
    background-color: green;
    left: 0px;
    top: 200px;
  }

  100% {
    background-color: red;
    left: 0px;
    top: 0px;
  }
}

.__show__error__above {
  position: absolute;
  top: 30px;
  z-index: 5;
  width: 50% !important;
  height: 30px;
  background: #942911;
}

.__checkbox__ {
  width: 37px !important;
  border: 10px !important;
  height: 34px !important;
  border-radius: 50% !important;
  box-sizing: border-box !important;
  /* border: 2px solid #000000; */
  border-radius: 8px;
}

.container {
  max-width: 100% !important;
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

@media(max-width: 986px) {
  .__hide__side__image {
    display: none !important;
  }
}

.title {
  /* font-family: Roboto; */
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  margin-bottom: 5%;
}

.form-container {
  padding: 15%;
  padding-bottom: 5%;
}

.form-control {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 2rem;
}

.form-select {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 2rem;
}

.loginBtn {
  width: 100%;
}

.form-label {
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  margin-top: 20px;
}

.instruction {
  font-size: 22px;
  margin-top: 20px;
}

.link-span {
  color: #1877F2;
  font-weight: bold;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 25%;
  padding-top: 0;
  padding-bottom: 30px;
  font-size: 18px;
  line-height: 21px;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #CCDBF0;
  ;
}

.separator:not(:empty)::before {
  margin-right: 10px;
}

.separator:not(:empty)::after {
  margin-left: 10px;
}

.social-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.middle-image {
  margin-left: 15px;
  margin-right: 10px;
}

.sideImg {
  width: 100%;
  height: 100vh;
}

.full__image {
  width: 100%;
  height: 100%;
}

.message {
  font-size: 32px;
  line-height: 37px;
  text-align: center;
  padding: 20%;
  margin-top: 25%;
}

.steps {
  max-width: 800px;
}

.form-check-input[type=radio] {
  border-radius: 4px;
  margin-top: 10px;
}

.form-check-label {
  font-size: 22px;
}

.finish-container {
  padding: 15%;
  padding-left: 30%;
  padding-right: 30%;
}

.finish-text {
  font-size: 32px;
  line-height: 37px;
  text-align: center;
  margin-bottom: 15%;
}

.borderBtn {
  border-radius: 10px;
  font-weight: bold;
  font-size: 26px;
  margin-top: 20px;
  padding: 22px;
  border: 1px solid #1877F2;
  color: #1877F2;
  background-color: #ffffff;
  width: 100%;
}

.imageupload-holder {
  padding: 70px;
  border: 1px dotted #000000;
  border-radius: 10px;
  text-align: center;
  /* width: 50%; */
}

.subtitle {
  font-size: 28px;
  font-weight: 400;
}

.desc {
  font-size: 22px;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 20px;
}

.btnGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5%;
}

.backBtn {
  border-radius: 10px;
  font-weight: bold;
  font-size: 26px;
  margin-top: 20px;
  padding: 22px;
  border: 1px solid #1877F2;
  color: #1877F2;
  background-color: #ffffff;
}

.continueBtn {
  background: #1877F2;
  border-radius: 10px;
  font-weight: bold;
  font-size: 26px;
  margin-top: 20px;
  padding-left: 50px;
  padding-right: 50px;
  /* padding: 22px; */
}

.cardHolder {
  background: #FBFAFA;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 20px;
  margin: 10%;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.infocard {
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-top: 0;
}

.name {
  font-size: 26px;
}

.position {
  font-size: 18px;
}
