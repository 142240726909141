/* ./src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap');

body {
  display: contents;
  /* height: 100vh !important; */
  height: 100vh;
  background-color: white !important;
  /* Change the whole body color */
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font {
    font-size: calc(10px + 1vmin);
    font-weight: 400;
}


*:focus {
    border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.__btn__ {
  padding: 0px 20px !important;
  color: aliceblue !important;
  background: #1877F2 !important;
  font-size: 18px !important;
  border-radius: 10px !important;
  font-weight: bolder !important;
  height: 50px;
}

.__btn__outlined__ {
  padding: 0px 20px !important;
  color: #2B81F3 !important;
  background: transparent !important;
  border: 1px solid #1877F2 !important;
  font-size: 18px !important;
  font-weight: bolder !important;
  border-radius: 10px !important;
  height: 50px;
}

.btn {
  background: #1877F2;
  border-radius: 10px;
  font-weight: bold;
  font-size: 16px !important;
}

html {
  background-color: #FAFAFA;
}

.cursor {
  cursor: pointer;
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"] {
  font-size: 18px;
}

label {
  font-size: 12px;
}


.spinner-center {
  z-index: 2;
  margin: auto;
  left: 50%;
  right: 50%;
}

/* Animation */

.fade-image-in {
  animation: fadeIn 6s;
  -webkit-animation: fadeIn 6s;
  -moz-animation: fadeIn 6s;
  -o-animation: fadeIn 6s;
  -ms-animation: fadeIn 6s;
}

.fade-in-content {
  animation: fadeIn 2s;
  -webkit-animation: fadeIn 2s;
  -moz-animation: fadeIn 2s;
  -o-animation: fadeIn 2s;
  -ms-animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.custom__button {
  color: darkgrey !important;
  border: 1px solid !important;
  background: #FFFFFF !important;
}

.radius {
  border-radius: 20px !important;
}

.cancel__button {
  border-radius: 10px !important;
  border: 2px solid #1877F2 !important;
  color: #1877F2 !important;
}

.submit__button {
  background: black;
  color: white !important;
  border-radius: 10px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.submit__button:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.custom__dropdown__select {
  /* padding: 16px; */
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center !important;

}

.custom__dropdown__select:hover {
  /* background-color: #f1f1f1 */
}

.dropbtn {
  background-color: #4CAF50;
  color: black;
  padding: 16px;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  border-radius: 10px;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  border-radius: 10px;
  position: absolute;
  background-color: #f9f9f9;
  /* width: 100px; */
  /* left: -40px; */
  min-width: 100%;
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
  /* z-index: 1; */
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  border-radius: 10px;
  display: block;
}

.dropdown-content span {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  border-radius: 10px;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1
}

.dropdown-content span:hover {
  background-color: #f1f1f1
}


/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #F2F2F2;
}