.__main__menu__nav {
    display: flex;
    background: #FFFFFF;
    top: 0 !important;
    position: -webkit-sticky !important;
    position: sticky !important;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: content;
    height: 60px;
    left: 0px;
    top: 0px;
    right: 0;
    left: 0;
    z-index: 1000;
    background: #FFFFFF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
}

.button__search {
    background-color: #F4F4F4 !important;
}

@media only screen and (max-width: 768px) {
    .button__search {
        display: none !important;
    }
}

.__large__screen__menu {
    display: flex;
    gap: 10px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    order: 1;
    flex-grow: 0;
}

.__small__screen__menu .__modal__ {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    overflow-y: none;
    position: absolute;
    padding: 0px;
    height: 100vh;
    left: 0px;
    right: 0px;
    top: 0px;
    z-index: 11;
    background: #ffffff;
}

.__close__menu {
    cursor: pointer;
    border: none;
    background-color: transparent;
}

.__signup__item__ .__login_item {
    cursor: pointer;
}

/* Nav items */
.__nav__item {
    cursor: pointer;
}

/* Login nav item */
.__login__nav__item:hover {
    color: #F39493;
}

/* Sign up nave item */
.__signup__nav__item {
    padding: 10px;
    border-radius: 20px;
    background: #F39493;
}

.__signup__nav__item:hover {
    background: rgba(0, 0, 0, 0.06);
    color: #F39493;
}

/* Mobile nav menu */
.__small__screen__menu div p {
    font-size: 40px;
    font-weight: 500;
}

/* Media query */
@media(min-width: 768px) {
    .__small__screen__menu {
        /* display: none; */
    }
}

@media(max-width: 768px) {
    .__large__screen__menu {
        /* display: none; */
    }
}

@media(min-width: 900px) {
    .__input__ {
        display: flex;
        width: 100% !important;
    }

}


/* Imported */

.container {
    max-width: 100% !important;
    margin: 0 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.login-container {
    /* padding-top: 50px; */
    /* font-family: Roboto; */
}

.title {
    /* font-family: Roboto; */
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    margin-bottom: 5%;
}

.form-container {
    padding: 15%;
    padding-bottom: 5%;
}

.row>* {
    /* padding-right: 0; */
}

.form-control {
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 2rem;
}

.form-select {
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 2rem;
}

.btn {
    background: #1877F2;
    border-radius: 10px;
    font-weight: bold;
    font-size: 26px;
    margin-top: 20px;
    padding: 22px;
}

.loginBtn {
    width: 100%;
}

.form-label {
    font-size: 22px;
    line-height: 26px;
    color: #000000;
    margin-top: 20px;
}

.instruction {
    font-size: 22px;
    margin-top: 20px;
}

.link-span {
    color: #1877F2;
    font-weight: bold;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 25%;
    padding-top: 0;
    padding-bottom: 30px;
    font-size: 18px;
    line-height: 21px;
}

.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #CCDBF0;
    ;
}

.separator:not(:empty)::before {
    margin-right: 10px;
}

.separator:not(:empty)::after {
    margin-left: 10px;
}

.social-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.middle-image {
    margin-left: 15px;
    margin-right: 10px;
}

.sideImg {
    width: 100%;
    height: 100vh;
}

.message {
    font-size: 32px;
    line-height: 37px;
    text-align: center;
    padding: 20%;
    margin-top: 25%;
}

.steps {
    max-width: 800px;
}

.form-check-input[type=radio] {
    border-radius: 4px;
    margin-top: 10px;
}

.form-check-label {
    font-size: 22px;
}

.finish-container {
    padding: 15%;
    padding-left: 30%;
    padding-right: 30%;
}

.finish-text {
    font-size: 32px;
    line-height: 37px;
    text-align: center;
    margin-bottom: 15%;
}

.borderBtn {
    border-radius: 10px;
    font-weight: bold;
    font-size: 26px;
    margin-top: 20px;
    padding: 22px;
    border: 1px solid #1877F2;
    color: #1877F2;
    background-color: #ffffff;
    width: 100%;
}

.imageupload-holder {
    padding: 70px;
    border: 1px dotted #000000;
    border-radius: 10px;
    text-align: center;
    /* width: 50%; */
}

.subtitle {
    font-size: 28px;
    font-weight: 400;
}

.desc {
    font-size: 22px;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 20px;
}

.btnGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5%;
}

.backBtn {
    border-radius: 10px;
    font-weight: bold;
    font-size: 26px;
    margin-top: 20px;
    padding: 22px;
    border: 1px solid #1877F2;
    color: #1877F2;
    background-color: #ffffff;
}

.continueBtn {
    background: #1877F2;
    border-radius: 10px;
    font-weight: bold;
    font-size: 26px;
    margin-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
    /* padding: 22px; */
}

.cardHolder {
    background: #FBFAFA;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 20px;
    margin: 10%;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.infocard {
    margin: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin-top: 0;
}

.name {
    font-size: 26px;
}

.position {
    font-size: 18px;
}