.button__outlined {
  flex-direction: row;
  width: 100% !important;
  background: #FFFFFF !important;
  align-items: center !important;
  justify-content: center !important;
  font-weight: bold !important;
  font-size: 14px !important;
  color: #DC4405 !important;
  border-radius: 25px !important;
  height: 42px;
  border: 2px solid #DC4405 !important;
  cursor: pointer
}

.button__search {
  flex-direction: row;
  background: #F4F4F4 !important;
  align-items: center !important;
  justify-content: center !important;
  font-weight: bold !important;
  font-size: 14px !important;
  border-radius: 25px !important;
  height: 42px;
  cursor: pointer
}

.button__search__middle__section {
  width: 100% !important;
  background: #F4F4F4 !important;
  align-items: center !important;
  justify-content: space-between !important;
  font-size: 14px !important;
  border-radius: 25px !important;
  height: 42px;
  cursor: pointer
}

.sign__up__button {
  flex-direction: row;
  /* flex: auto; */
  background: #FFFFFF !important;
  border: 2px solid #DC4405 !important;
  border-radius: 25px !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 16px !important;
  color: #DC4405 !important;
  padding: 6px;
  cursor: pointer
}

.profiles__button {
  flex-direction: row;
  flex: auto;
  background: #FFFFFF !important;
  border-radius: 25px !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 16px !important;
  padding: 6px;

  cursor: pointer
    /* height: 42px; */
}

.button__contained {
  flex-direction: row;
  border-style: none;
  width: 50% !important;
  line-height: 19px;
  color: #ffff !important;
  background: #DC4405 !important;
  padding: 5px 10px;
  height: 42px;
  font-size: 14px !important;
  border-radius: 25px !important;
  cursor: pointer;
}

  .__background__ {
    /* position: absolute !important; */
    z-index: 100 !important;
  }

@media only screen and (max-width: 768px) {
  .button__contained {
    flex-direction: row;
    border-style: none;
    width: 100% !important;
  }

  .__background__ {
    display: flex !important;
    width: 100% !important;
    height: 640px !important;
  }
}


.__background__ {
  background: #1E666D !important;
}

.__call__to__action__ {
  margin-top: 10px !important;
  position: relative !important;
  z-index: 100 !important;
}


@media only screen and (min-width: 300px) and (max-width: 768px) {
  .__background__ {
    margin: 0px !important;
    display: flex;
    flex-direction: column !important;
    width: 100% !important;
    height: 640px !important;
  }

  .__bottom__content__section__ {
    padding: 0px !important;
  }

  .__full__image__ {
    width: 100% !important;
    margin: 0px;
  }

  .__background__ p {
    font-size: 16px !important;
  }

  .hero {
    padding: 100px 0px 0px !important;
  }

}


@media only screen and (min-width: 769px) and (max-width: 1300px) {
  .__image__push__down__ {
    width: 40% !important;

  }

  .__background__ {
    margin: 0px !important;
    display: flex;
    flex-direction: column !important;
    width: 100% !important;
    height: 640px !important;
  }

  .hero {
    padding: 100px 0px !important;
  }
}

@media screen and (max-width: 1440px) {

  .__make__column__ {
    flex-direction: column;
  }

  .__image__push__down__ {
    width: 100% !important;
  }

  .hero {
    padding: 60px 0px 0px !important;
  }

}


@media screen and (min-width: 1440px) {
  .__background__ {
    height: 300px !important;
  }

  .__image__push__down__ {
    margin-bottom: -100px !important;
  }

  .hero {
    padding: 60px 0px 0px !important;
  }

}