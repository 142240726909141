.__bounce__donate__button {
  animation: bounce 0.3s infinite alternate-reverse;
  cursor: pointer
}


@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-6px);
  }
}