/* Horizontal view */
.__row__ {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;
}

.font {
    font-size: 10px;
}

.__upload__file {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    cursor: pointer !important;
    padding: 40px;
    background-color: white;
    width: 100% !important;
    font-size: 20px;
    height: 100% !important;
    border: 2px dashed #000000 !important;
    box-sizing: border-box !important;
    border-radius: 10px !important;
}

.__upload__button__primary {
    justify-content: center !important;
    background-color: white;
    align-items: center !important;
    cursor: pointer !important;
    padding: 16px 10px;
    color: aliceblue;
    font-weight: 600;
    color: #ffffff !important;
    border-radius: 6px;
    border-style: none;
    height: 60px;
    font-size: 20px;
}

.__upload__button__ {
    justify-content: center !important;
    background-color: white;
    align-items: center !important;
    cursor: pointer !important;
    padding: 16px 10px;
    color: aliceblue;
    border: 2px solid #1877F2 !important;
    font-weight: 600;
    color: #1877F2 !important;
    border-radius: 6px;
    border-style: none;
    height: 60px;
    font-size: 20px;
}

.card {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    cursor: pointer !important;
    padding: 16px 10px;
    border-radius: 6px;
    height: 60px;
    width: 100% !important;
    font-size: 20px;
}

.card.__card__selected {
    background: #C6C4FB !important;
    color: #000;
}

/* Vertical view */
.__column__ {
    display: flex;
    flex-direction: column;
    align-items: center;
}

label {
    font-size: 16px !important;
    font-weight: 400 !important;
}

/* Input field container*/
.__input__field__container__ {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}

.__textarea__field__ {
    flex-grow: stretch;
    padding: 10px 10px 0px !important;
    height: 100px !important;
        font-size: 16px;
    font-family: 'Roboto', sans-serif !important;
    border: 1px solid #000000 !important;
    color: #141414 !important;
    border-radius: 10px;
}

/* Input field */
.__input__field__container__ .__input__field__ {
    flex-grow: stretch;
    padding: 0px 10px 0px !important;
    height: 50px;
    font-size: 16px;
    width: auto !important;
    left: 0px;
    border: 1px solid #000000;
    border-radius: 10px;
}


/* Button */
.__button__ {
    padding: 10px !important;
    color: aliceblue !important;
    background: #1877F2 !important;
    height: 50px;
}

@-webkit-keyframes ripple {
    0% {
        width: 0;
        height: 0;
        opacity: .5;
    }

    100% {
        width: 150px;
        height: 150px;
        opacity: 0;
    }
}

@keyframes ripple {
    0% {
        width: 0;
        height: 0;
        opacity: .5;
    }

    100% {
        width: 150px;
        height: 150px;
        opacity: 0;
    }
}

.__ripple__:before {
    content: '' !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    width: 0 !important;
    height: 0 !important;
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
    border-radius: 50% !important;
    background-color: currentColor !important;
    visibility: hidden !important;
    z-index: 2 !important;
}

.__ripple__:not(:active):before {
    -webkit-animation: ripple 0.4s cubic-bezier(0, 0, 0.2, 1) !important;
    animation: ripple 0.4s cubic-bezier(0, 0, 0.2, 1) !important;
    -webkit-transition: visibility .4s step-end !important;
    transition: visibility .4s step-end !important;
}

.__ripple__:active:before {
    visibility: visible !important;
}

/* Small screen media */
@media(max-width: 786px) {
    .__address__column__ {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
    }
}

.container {
    /* display: flex; */
    /* height: 100vh; */
    justify-content: space-around;
    /* align-items: center; */
    color: #fff;
    animation: expand .8s ease forwards;
    /* background-color: var(--secondary-color); */
    /* position: relative; */
    transition: all .8s ease;
}

.container_content {
    width: 50%;
}

.btns_more {
    background: transparent;
    border: 1px solid var(--contrast-color);
    border-radius: 50px;
    padding: 8px 12px;
    color: #BF307F;
    font-size: 16px;
    /* text-transform: uppercase; */
    position: relative;
    margin-top: 15px;
    outline: none;
    transform: translateY(50px);
    animation: slideIn .8s ease-in-out forwards 1s;
}

.container_img {
    width: 100%;
    animation: slideIn 1.5s ease-in-out forwards;
}

@keyframes slideIn {
    0% {
        transform: translateX(500px) scale(.2);
    }

    100% {
        transform: translateX(0px) scale(1);
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(300px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes expand {
    0% {
        transform: translateX(1400px);
    }

    100% {
        transform: translateX(0px);
    }
}