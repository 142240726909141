.single-campaign {
  background: var(--clr-white);
  border-radius: var(--radius);
  margin: 1rem 0;
  box-shadow: var(--dark-shadow);
  transition: var(--transition);
  cursor: pointer;
}

.single-campaign img {
  width: 100%;
  max-width: 100%;
  height: 13rem;
  object-fit: fill;
  border-top-right-radius: var(--radius);
  border-top-left-radius: var(--radius);
  transition: all 0.2s linear;
}

.campaign-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 17px;
  color: #111;
}

.category {
  text-align: center;
  margin-top: 5px;
}

.movie-info h2 {
  font-size: 1rem;
  font-weight: 900;
  letter-spacing: 2px;
  line-height: 8px;
}

h4 {
font-size: 13px;
color: #617d98;
}

.single-campaign .ratio-section {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 23px;
  width: 100%;
}

 .single-campaign .ratio-section > div {
  display: flex;
}

 .single-campaign .ratio-section > div i {
  font-size: 20px;
  margin-right: 5px;
}

.single-campaign .ratio-section > div i.active {
  color: #2b2eff;
}


 .single-campaign .ratio-section > div h5 {
  font-size: 15px;
  text-align: center;
  letter-spacing: 1px;
}

.single-campaign button {
  background: transparent;
  border-color: transparent;
  text-transform: capitalize;
  color: var(--clr-primary-5);
  font-size: 1rem;
  cursor: pointer;
  padding-left: 0.25rem;
  
}

.single-campaign footer {
  padding: 1.5rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.single-campaign .btn {
  display: block;
  width: 200px;
  margin: 1.5rem auto 0.50rem auto;
  color: #fff;
  background: #7258E9;
  letter-spacing: var(--spacing);
  padding: 0.7rem 0.5rem;
  font-size: 17px;
  font-weight: 300;
  border-radius: 1px;
  transition: 0.5s ease-in-out;
}

.single-campaign .btn:hover {
  color: #fff;
  background-color: #051747;
  letter-spacing: 3px;
}

.grid-list {
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 45px;
  margin: auto;
}

.campaign-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	grid-gap: 30px;
	margin-top: 20px;
}

.campaign-post {
	position: relative;
	height: 480px;
  background-color: #f7f7f7; /* Background color */
  border: 1px solid #555;
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow for depth */

  /* Additional styles for an impressive look */
  transition: transform 0.3s ease; /* Smooth transition on hover */
  /* overflow: hidden; */
}

.campaign-post .cover-img {
	width: 100%;
	height: 200px;
	object-fit: contain;
	border-radius: 10px;
}

.campaign-post .title {
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 80%; /* Adjust width as needed */
  text-align: center; /* Center-align the text */
}

.campaign-post .description {
	font-size: 16px;
  color: #828282;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 80%; /* Adjust width as needed */
  text-align: center; /* Center-align the text */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  line-clamp: 6;
  box-orient: vertical;
  overflow: auto;
  /* white-space: nowrap; */
}

.campaign-post .card-details {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
	position: absolute;
	bottom: 0;
	width: 100%;
}

.card-details .lh-details {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.card-details .date {
	font-size: 14px;
	color: #828282;
}

.campaign-post .read-more {
	font-size: 16px;
	font-weight: 600;
	color: #00a8ff;
	text-decoration: none;
	margin-top: 10px;
	margin-right: 5px;
}

.campaign-post .read-more:hover {
	text-decoration: underline;
  cursor: pointer;
}

.campaign-post .author-img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	object-fit: cover;
	margin-right: 10px;
	margin-left: 10px;
}

.campaign-post .description::-webkit-scrollbar {
  width: 0; /* Width of scrollbar set to 0 */
}

ul {
	list-style: none;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  color: #333;
  text-decoration: none;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.page-link:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.pagination .page-number {
	font-size: 16px;
	font-weight: 600;
	color: #00a8ff;
	background: #fff;
	padding: 10px 20px;
	border-radius: 10px;
	cursor: pointer;
	transition: all 0.5s ease;
}

.pagination .page-number:hover {
	color: #fff;
	background: #00a8ff;
}

.pagination .active {
	color: #fff;
	background: #00a8ff;
}

.pagination .active:hover {
	color: #00a8ff;
	background: #fff;
}

.btn {
  background: var(--clr-primary-5);
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: var(--radius);
  text-transform: capitalize;
  color: var(--clr-white);
  letter-spacing: var(--spacing);
  border-color: transparent;
  cursor: pointer;
  margin-top: 2rem;
  font-size: 1.2rem;
}

.user-post {
	position: relative;
	height: 100%;
  width: 100%;
  padding: 10px;
  background-color: #f7f7f7; /* Background color */
  border: 1px solid #555;
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow for depth */
  /* Additional styles for an impressive look */
  transition: transform 0.3s ease; /* Smooth transition on hover */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.user-post .cover-img {
	width: 130px;
	height: 130px;
	object-fit: cover;
	border-radius: 50%;
  clip-path: circle(50% at 50% 50%);
  margin-bottom: 10px;
}

.user-post .title {
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  width: 80%; /* Adjust width as needed */
  text-align: center; /* Center-align the text */
}

.user-post .description {
	font-size: 16px;
  color: #828282;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  width: 80%; /* Adjust width as needed */
  text-align: center; /* Center-align the text */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
}

.user-post .card-details {
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* margin-bottom: 10px; */
	/* position: absolute; */
	/* bottom: 0; */
	width: 100%;
  margin-top: auto;
  overflow: hidden;
}

.user-post .read-more {
	font-size: 16px;
	font-weight: 600;
	color: #00a8ff;
	text-decoration: none;
	margin-top: 10px;
	margin-right: 5px;
}

.user-post .read-more:hover {
	text-decoration: underline;
  cursor: pointer;
}

.user-post .author-img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	object-fit: cover;
	margin-right: 10px;
	margin-left: 10px;
}
.__card__:hover {
  background-color: #FAFAFA;
  box-shadow: 0px 2px 2px rgba(60, 60, 60, 0.1);
  border-radius: 10px;
  cursor: pointer;
}

.custom-paper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFD700; /* Change the background color */
  border-radius: 15px;
  width: 300px;
}

.header {
  display: flex;
  align-items: center;
}

.clock-icon {
  margin-right: 100px;
}

.date {
  font-size: 12px;
  color: #333;
}

.content {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.profile-image {
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

.text-content {
  font-size: 14px;
  color: #333;
}
