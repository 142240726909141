.input {
    /* display: flex; */
    /* flex-direction: column; */
    flex-grow: stretch;
    /* width: 100% !important; */
    padding: 0px 0px 0px 10px;
    height: 43px;
    left: 0px;
    border: 2px solid #E0E0E0;
    border-radius: 4px;
}

.inputTextarea {
    /* display: flex; */
    /* flex-direction: column; */
    flex-grow: stretch;
    width: 100% !important;
    padding: 0px 0px 0px 10px;
    height: 70px;
    left: 0px;
    padding-top: 10px;
    border: 2px solid #E0E0E0;
    border-radius: 4px;
}

@media(min-width: 786px) {
    .input {
        display: flex;
    }
}

@media(min-width: 786px) {
    .nav {
        display: none;

    }

    .navItem {
        display: block;
        justify-content: center;
        align-items: center;
        justify-items: center;
    }
}

@media(min-width: 900px) {
    .input {
        display: flex;
        width: 100% !important;
    }
}

.select {
    padding: 0px 0px 0px 10px;
    width: 100%;
    height: 43px;
    left: 0px;
    top: 25px;
    border: 2px solid #E0E0E0;
    border-radius: 4px;
}

.select option {
    padding-right: 20px;
}

.chefProfile {
    bottom: -40px !important;
    margin-left: 20px !important;
    position: absolute;

}


@media (max-width: 768px) {
    .chefProfile {
        margin-left: 0px !important;
        bottom: -100px !important;
    }
    

}

.editProfileButton {
    bottom: 40px;
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
    border-radius: 100% !important;
}

.upload {
    display:none;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
}

.editCoverButton {
    top: 30px !important;
    right: 0px !important;
    float: right !important;
    /* border-radius: 100% !important; */
    border: 0.1px solid #C4C4C4 !important;
}

.inputField {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}


@keyframes spinner {
    0% {
        transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
        transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
}
.__spinner__::before {
    animation: 1.9s linear infinite spinner;
    animation-play-state: inherit;
    border: solid 2px #cfd0d1;
    border-bottom-color: #77b0d3;
    border-radius: 50%;
    content: "";
    height: 20px;
    width: 20px;
    position: absolute;
    /* top: 10%; */
    /* left: 10%;    */
    transform: translate3d(-50%, -50%, 0);
    will-change: transform;
}